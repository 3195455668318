import React, { useEffect } from 'react';
import { globalHistory } from '@reach/router'
import Generic from 'components/Layouts/Generic';
import gtmEvents from '../utils/functions/gtmFunctions.js';

const title = 'Aide';

const body = `
<h3>À venir.</h3>
`;

const CommunautesAutochtones = (props) => {
    useEffect(() => {
      gtmEvents.sendVirtualPageView(title, globalHistory.location.href.href, globalHistory.location.pathname);
  }, []);
  return <Generic location={props?.location} title={title} body={body} />;
};

export default CommunautesAutochtones;
