import styled, { css } from 'styled-components';
import Typography from 'components/UI/Typography';
import { Link } from 'gatsby';

// Container

const containWidth = css`
  max-width: 1210px;
  padding: 0 40px;
  margin: 0 auto;
`;

export const BackgroundEventContainer = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tab.is-active {
    .Typography {
      color: ${(props) => props.theme.colors.purple} !important;
    }
  }
`;

// Main Page Body

export const MainBodyContainer = styled.div`
  padding: 100px 0 0;
  min-height: 500px;

  .main-body-animate-enter {
    opacity: 0;
  }
  .main-body-animate-exit {
    opacity: 1;
  }
  .main-body-animate-enter-active {
    opacity: 1;
  }
  .main-body-animate-exit-active {
    opacity: 0;
  }
  .main-body-animate-enter-active,
  .main-body-animate-exit-active {
    transition: 500ms ease;
    transition-property: opacity;
  }
`;

// Main Page Body - Sélection d'événements

export const RelatedEventsContent = styled.div`
  ${containWidth};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    width: calc(50% - 12.5px);
    margin-bottom: 120px;
  }
`;

export const RelatedEventSingular = styled(Link)``;

export const RelatedEventImage = styled.img`
  width: auto;
  height: 330px;
  margin: 0 auto;
`;

export const RelatedEventImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  background: ${(props) => props.theme.colors.light_brown};
`;

export const RelatedEventDateTypography = styled(Typography)`
  margin-bottom: 23px;
  color: ${(props) => props.theme.colors.blue};
  text-transform: uppercase;
  font-size: 16px !important;
`;

export const RelatedEventTitleTypography = styled(Typography)`
  padding-right: 40px;
  line-height: 1.3 !important;
  font-weight: 400 !important;
  transition: color 0.3s ease;

  :hover {
    color: ${(props) => props.theme.colors.purple};
  }
`;

// Footer Breadcrumb

export const FooterBreadcrumbContainer = styled.div`
  margin: 0 auto;
  background: ${(props) => props.theme.colors.primary};
  padding: 25px 0;
  width: 100%;
`;

export const FooterBreadcrumbContent = styled.div`
  ${containWidth};
`;
