import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const HeaderContainer = styled.header`
  background: ${(props) => props.theme.colors.primary};
  text-align: center;
`;

export const HeaderTitle = styled(Typography)`
  color: ${(props) => props.theme.colors.blue};
`;

export const HeaderOverline = styled(Typography)`
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 30px;
  text-transform: uppercase;
`;

export const HeaderContent = styled.div`
  padding-top: 130px;
  max-width: 875px;
  margin: 0 auto;
`;
